@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Montserrat:wght@400;500;600;700&display=swap');
@import "./variables.scss";

html {
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: $background-color;
  color: $font-color;
  line-height: 2rem;
}

.welcomeText {
  font-size: 2rem;
  font-weight: 600;
  margin-top: 200px;
  text-align: center;
}

.primaryButton {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 30px 10px 30px;
  border-radius: 0.25rem;
}

.primaryButton:hover,
.primaryButton:active {
  box-shadow: $lift-shadow;
}

.next {
  background-color: $secondary-color;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.cancel {
  background-color: $warning-color;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.next:hover {
  background-color: $secondary-color-hover;
}

.next:active {
  background-color: $secondary-color-active;
}

.cancel:hover {
  background-color: $warning-color-hover;
}

.cancel:active {
  background-color: $warning-color-active;
}

.mainContainer {
  margin-left: 35vw;
  margin-top: 0;
}

.videoContainer {
  width: 35vw;
  position: absolute;
  z-index: 99;
  background-color: rgba(0, 0, 0, 1);
  height: calc(50% - 35px);
}

.userVideoContainer {
  top: calc(50% + 35px);
  right: default;
  width: 35vw;
  height: calc(50% - 35px);
  z-index: default;
}

.userVideoFull {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 200px;
  height: 200px; 
}

.partnerVideoContainer {
  top: 70px;
}

.partnerVideoFull {
  height: 100%;  
  width: 100vw;
  top: 0;
  
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: $lift-shadow;
}

.userVideo {
  /* temp */
}

.partnerVideo {
  /* temp */
}

.controlsContainer {
  position: absolute;
  top: calc(100% - 100px);
  align-items: center;
  justify-content: space-around;
  height: 100px;
  z-index: 999;
  width: 35vw;
  display: flex;
}

.controlsFull {
  width: 100vw;
}

.iconContainer {
  padding: 12px;
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;
  border-color: $primary-color;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  background-color: transparent;
  box-shadow: $lift-shadow;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.iconContainer:hover {
  background-color: $button-hover-color;
}

.iconContainer svg {
  width: 100%;
  height: auto;
}

.iconBasic {
  color: $primary-color-background;
}

.iconAlternative {
  color: $warning-color;
}

.inputContainer {
  bottom: 0;
  width: 100%;
  z-index: 2;
  height: 70px;
}

.chatInput {
  width: calc(100% - 220px);
  border-width: 4px;
  border-style: solid;
  border-color: $primary-color;
  padding: 13px 80px 12px 12px;
  font-size: 2rem;
  outline: none;
  height: 100%;
  background-color: $background-color;
  color: $font-color;
}

.chatInput::placeholder {
  color: rgba($font-color, 0.8);
}

.attachmentButton {
  position: absolute;
  right: 140px;
  z-index: 3;
  margin-top: 18px;
  font-size: 2rem;
  color: $primary-color;
  cursor: pointer;
}

.chatButton {
  border: none;
  width: 120px;
  padding: 17px 0 16px 0;
  cursor: pointer;
  background: $primary-color;
  outline: none;
  color: $font-color;
  font-size: 2rem;

  &:hover {
    background: $primary-color-background;
  }
}

@media screen and (min-width: 861px) {
}

@media screen and (max-width: 860px) {
  html {
    font-size: 16px;
  }

  .mainContainer {
    margin-left: 0px;
    margin-top: 50vh;
  }

  .videoContainer {
    width: 100%;
    z-index: 99;
    height: 50%;
  }

  .videoContainer {
    width: 100%;
    height: 50%;
  }

  .userVideoContainer {
    top: 2%;
    right: 2%;
    width: 80px;
    height: 80px;
    z-index: 100;
  }
  
  .userVideoFull {
    width: 100px;
    height: 90px;
  }

  .partnerVideoContainer {
    top: 0;
  }

  .partnerVideoFull {
    height: 100%;
    left: 0;
  }

  .controlsContainer {
    top: calc(50% - 60px);
    height: 50px;
    z-index: 999;
    width: 100%;
  }

  .controlsFull {
    top: initial;
    bottom: 5%;
  }

  .iconContainer {
    cursor: none;
    padding: 10px;
    border-radius: 50%;
    border-width: 2px;
    width: 1.5rem;
    height: 1.5rem;
  }

  .welcomeText {
    margin-top: 60vh;
  }

  .chatInput {
    width: calc(100% - 116px);
    border-width: 3px;
    border-color: $primary-color;
    padding: 8px 42px 8px 8px;
    font-size: 1rem;
  }

  .inputContainer {
    height: 40px;
  }

  .attachmentButton {
    right: 70px;
    margin-top: 8px;
    font-size: 1.5rem;
  }

  .chatButton {
    width: 60px;
    padding: 11px 0 11px 0;
    font-size: 1rem;
  }
}
