@import "../../variables.scss";

.connecting{
  height: calc(40vh - 75px);
  width: calc(65vw);
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  background-color: transparent;
  z-index: 1;

  font-family: "Great Vibes", cursive;
  font-weight: 500;
  color: $primary-color;

}


@media screen and (max-width: 860px) {

  .connecting{
      height: calc(0vh);
      width: 100%;
      font-size: 2rem;
  }
}
