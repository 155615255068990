@import "../../variables.scss";

.welcomeContainer{
  height: calc(40vh - 75px);
  width: calc(65vw);
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  background-color: transparent;
  z-index: 1;

  font-family: "Great Vibes", cursive;
  font-weight: 500;
  color: $primary-color;

  -webkit-animation: cssAnimation 3s forwards; 
    animation: cssAnimation 3s forwards;
}

@keyframes cssAnimation {
  0%   {opacity: 1;}
  90%  {opacity: 1;}
  100% {opacity: 0;}
}
@-webkit-keyframes cssAnimation {
  0%   {opacity: 1;}
  90%  {opacity: 1;}
  100% {opacity: 0;}
}

.chatBox {
  height: calc(90vh - 150px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-left: 30px;
}

@media screen and (max-width: 860px) {
  .chatBox {
    height: calc(31vh - 45px);
    padding-bottom: 1px;
  }

  .welcomeContainer{
      height: calc(5vh);
      width: 100%;
  }
}
